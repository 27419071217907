/* src/App.css */
.App {
  max-width: 800px;
  margin: 0 auto;
  padding: 0px;
  font-family: Arial, sans-serif;
  padding-bottom: 0px; /* Adjust this value based on your footer's height */
}

h1 {
  color: #fff;
  text-align: center;
}

h2 {
  color: #444;
  margin-top: 20px;
}

.ingredient-section {
  margin-bottom: 20px;
}

.ingredient-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.ingredient-item {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.ingredient-item input {
  margin-right: 5px;
}

.macro-ratio-selector {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.macro-ratio-option {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.macro-ratio-option.selected {
  background-color: #007bff;
  color: white;
}

button {
  display: block;
  width: 200px;
  margin: 20px auto;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #218838;
}

button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.error {
  color: #dc3545;
  text-align: center;
}

.meal-plan {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.meal-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  text-align: center;
}

.meal-card img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.meal-card h3 {
  margin: 10px 0;
}

.meal-card p {
  margin: 5px 0;
}

.dot {
  transition: all 0.3s ease-in-out;
}

input:checked ~ .dot {
  background-color: #3B82F6; /* Tailwind's blue-500 */
}

body {
  font-family: 'Inter', sans-serif;
}

@media (max-width: 640px) {
  html {
    font-size: 14px;
  }

  input[type="number"] {
    font-size: 16px; /* Prevents zoom on focus in iOS */
  }

  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}


footer {
  height: 90px; /* Adjust this value to match your footer's height */
}

#edamam-badge {
  height: 20px; /* Adjust based on the actual height of the badge */
}

footer a {
  height: 20px; /* Adjust to make the button take up the remaining space */
  line-height: 10px; /* Center the text vertically */
  padding: 0px; /* Remove padding to ensure full-width */
}
